import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import { Flex } from 'grid-emotion';
import SocialLinks from './SocialLinks';

const Wrapper = styled.nav`
  /* background: ${props => props.theme.colors.white}; */
  color: ${props => props.theme.colors.greyDark};
  a {
    color: ${props => props.theme.colors.greyDark};
    &:hover {
      color: ${props => props.theme.colors.primaryLight};
    }
  }
`;

const StyledLink = styled(Link)`
  transform: translateX(0);
  transition: all 200ms ease-out;
  padding: 0;
  padding-right: 3.8rem;
  &:last-child{
    padding-right:0;
  }
  font-size:1rem;
  font-family: ${props => props.theme.fontFamily.heading};
  font-weight:bold;
  text-transform:uppercase;
  &:hover {
    color: ${props => props.theme.colors.bg};
    transform: translateX(-6px);
  }
  &.active{
    color: ${props => props.theme.colors.red};
  }
`;

const NavBarDesktop = styled.div`
  display: none;
	margin: 0 auto;
  .wideDiv {
    text-align: center;
    a {
      text-decoration: none;
      display: inline-block;
      padding: 0 2em;
    }
  }
  @media (min-width: ${props => props.theme.breakpoint.l}) {
    display: block;
  }

`;

const NavBarMobile = styled.div`
  position: absolute;
  top:50%;
  transform:translateY(-50%);
  right: ${props => props.theme.basicModulePadding.l.right};
  margin-top: 0!important;
  padding: 0 0 0 0;
  transition: none;
  z-index: 9999;
  box-shadow: 0;
  background: transparent;
  width:11rem;
  .socialLinks{
    display:none;
  }
  &.change {
    transition: background 0.35s ease-out, width 0.25s ease-out;
    top:0;
    background: white;
    width: 19rem !important;
    height: 27.7rem;
    transform:none;
    padding-right:${props => props.theme.basicModulePadding.l.right};
    padding-top:1.77rem;

    @media (max-width: ${props => props.theme.breakpoint.s}) {
      padding-right:${props => props.theme.basicModulePadding.s.right};
      padding-top:1.55rem;
      padding-bottom:5rem;
      min-height: Calc(22.7rem);/*desired height*/
      height:100vh;
      width:15.4rem !important;
    }
    right:0;
    box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.25);
    .socialLinks{
      display:flex;
      justify-content:space-around;
      width:100%;
      position:absolute;
      bottom:0;
      background:black;
      height:3.833rem;
      align-content: center;
      align-items: center;
      padding:0 ${props => props.theme.basicModulePadding.l.right};

      @media (max-width: ${props => props.theme.breakpoint.s}) {
        padding:0 ${props => props.theme.basicModulePadding.s.right};
      }
      .socialLinkObj{
        filter:invert(1);
      }
    }
  }
  &.change .bar1 {
    transform: rotate(-45deg) translate(-14px, 23px);
  }
  &.change .bar2 {
    opacity: 0;
  }
  &.change .bar3 {
    transform: rotate(45deg) translate(-2px, -10px);
  }
  .narrowLinks {
    display: none;
    /* opacity: 0; */
    transition: all 1s;
    padding-top:1.66rem;
    line-height:0.9rem;
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      line-height:0.85rem;
      padding-top:1.35rem;
    }
    a {
      text-decoration: none;
      display: block;
      clear: left;
      padding: 1em 0;
    }
  }
  &.change .narrowLinks {
     /* opacity: 1; */
    display: block;
  }
  @media (min-width: ${props => props.theme.breakpoint.l}) {
    display: none;
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    right: ${props => props.theme.basicModulePadding.s.right};
    line-height:0;
    &.change .bar1 {
      transform: rotate(-45deg) translate(-2px, 6px);
      height:4px;
    }
    &.change .bar3 {
      transform: rotate(45deg) translate(-8px, -12px);
      height:4px;
    }
  }
}
`;

const NavBurger = styled.div`
  display: inline-block;
  cursor: pointer;
  padding-right: 0;
  padding-left: 0;
`;

const NavBurgerBar = styled.div`
  width: 55px;
  height: 4px;
  background-color: ${props => props.theme.colors.red};
  margin: 0.77rem 0;
  transition: 0.4s;
  border-radius:5px;

  &:first-child {
    margin-top:0;
  }
  &:last-child {
    margin: inherit!important;
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    width: 26px;
    height:2px;
    margin: 0.4rem 0;
  }
`;

const burgerToggle = (e) => {
  e.currentTarget.parentNode.classList.toggle('change');
}

const Navbar = ({ links, social_links }) => {
  return (
    <Wrapper>
        <NavBarDesktop>
        {
          links.map(obj => {
            return <StyledLink partiallyActive={true} activeClassName="active" key={obj.link_label} to={obj.link_target}>{obj.link_label}</StyledLink>
          })
        }
        </NavBarDesktop>
        <NavBarMobile>
          <NavBurger onClick={burgerToggle}>
            <NavBurgerBar className='bar1'></NavBurgerBar>
            <NavBurgerBar className='bar2'></NavBurgerBar>
            <NavBurgerBar className='bar3'></NavBurgerBar>
          </NavBurger>
					<div className="narrowLinks">
          {
            links.map(obj => {
              return <StyledLink partiallyActive={true} activeClassName="active" key={obj.link_label} to={obj.link_target + "/"}>{obj.link_label}</StyledLink>
            })
          }
					</div>
          <SocialLinks className="socialLinks" data={social_links}></SocialLinks>
				</NavBarMobile>
    </Wrapper>
  );
};

export default Navbar;

Navbar.propTypes = {
  links: PropTypes.array.isRequired,
};

Navbar.defaultProps = {

};
