import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import { Flex } from 'grid-emotion';
import StaticImage from './StaticImage';

const SocialLink = styled.a`
  height: auto;
  display: inline-block;
  padding-right: 0.55rem;
  box-sizing: content-box;
  &:last-child {
    padding-right: 0;
  }
  .socialLinkObj {
    object-fit: contain;
    width: 100%;
    pointer-events: none;
  }
`;
const SocialLinksWrapper = styled.div`
  color: #282828;
`;
const SocialLinks = ({ data, className }) => (
  <SocialLinksWrapper className={className}>
    {data &&
      data.map((link, idx) => (
        <SocialLink key={idx} href={link.social_link.url} target={link.social_link.target} rel="noreferrer noopener">
          <StaticImage className="socialLinkObj" image_src={link.social_icon.url} />
        </SocialLink>
      ))}
  </SocialLinksWrapper>
);
export default SocialLinks;

SocialLinks.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};
