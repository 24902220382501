import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';

import theme from '../../config/theme';

import StyledButton from './StyledButton';

const WrapperContactForm = styled(Flex)`
  /* flex-basis: calc(99.9% - 4rem); */
  /* min-height: 10rem; */
  /* width: calc(99.9% - 4rem); */
  /* text-align: center; */
  position: relative;
  z-index:1;
  h4 {
    ${props => props.theme.font.h1};
    height: 100%;
    text-align: right;
    padding: 3rem 3rem 2rem ${props => props.theme.basicModulePadding.left};

  }
  form {
    padding-top: 3rem;
    padding-bottom: 3rem;
    z-index: 99;
    margin-bottom:0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width:40%;
  }
  .wrapperStyledButton {
    display:inline-block;
    padding: 0rem 1rem 1rem 0;
    button{
      margin-top:0;
      margin-bottom:0;
      top:-1px;
      line-height:1.7rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.l}){
    flex-direction:column;
    h4{
      ${props => props.theme.font.h1.s}
      text-align:left;
      padding:${props => props.theme.basicModulePadding.l.top} ${props => props.theme.basicModulePadding.l.right} 0 ${props => props.theme.basicModulePadding.l.right};
      width:100%;
    }
    form{
      width:100%;
      padding:0 ${props => props.theme.basicModulePadding.l.right} ${props => props.theme.basicModulePadding.l.bottom} ${props => props.theme.basicModulePadding.l.right};
      .wrapperStyledButton {
        width:unset;
        button{
          line-height:1rem;
        }

      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
    flex-direction:column;
    padding: ${props => props.theme.basicModulePadding.s.top} ${props => props.theme.basicModulePadding.s.left};
    h4, form{
      width:100%;
    }
    h4{
      ${props => props.theme.font.h1.s}
      text-align:left;
      padding:0;
      margin-bottom:1.11rem;
    }
    form{
      padding-top:0;
      padding-left:0;
      padding-right:0;
      padding-bottom:0;
      margin-left:-0.33rem;
      fieldset{
        padding-left:0.33rem;
        padding-right:0.33rem;
      }

      .wrapperStyledButton {
        padding:0;
        padding-left:0.33rem;
        padding-right:0.33rem;
        button{
          line-height:1.7rem;
        }
      }
    }
  }
  h4, h6 {
    color: ${props => props.theme.colors.white};
  }
  h6 {
    margin-bottom: 0.5rem;
  }

  @media (max-width: ${props => props.theme.breakpoint.m}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
  button {
    line-height: 1.45rem;
    top: 2px;
    position: relative;
    a {
      padding: 0.6rem 0.88rem !important;
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        padding: 0rem 1.54rem !important;
      }
    }
    @media (max-width: ${props => props.theme.breakpoint.l}) {
      width:auto
    }
  }
`;

const FieldsetContactForm = styled.fieldset`
  border: none;
  display: inline-block;
  padding: 0rem 1rem 1rem 0;
  width: ${props => props.fieldWidth && props.fieldWidth.indexOf("75%") > -1 ? "80%": "40%"};
  margin-bottom: 0;
  textarea{
    overflow:hidden;
  }
  textarea:-moz-ui-invalid {
    box-shadow: none;
  }
  label{
    padding-top:0;
  }
  @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
    text-align:left;
    width: ${props => props.fieldWidth && props.fieldWidth.indexOf("75%") > -1 ? "100%": props.fieldWidth};
    input, textarea {
      max-width:unset;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
    textarea{
        height:4.5em;
    }
  }
`;
const rowProps = {};
const ContactForm = ({ title, target, fields }) => (

  <WrapperContactForm>
    {ReactHtmlParser(title.html)}
    <form>
      {fields.map((field, idx) => (
        <FieldsetContactForm fieldWidth={field.percentage_width} key={idx}>
          <label htmlFor={field.field_name}>{ReactHtmlParser(field.field_title.html)}</label>
          {(field.field_type == "textarea") &&
            <textarea
            name={field.field_name}
            id={field.field_name}
            placeholder={field.field_placeholder}
            rows="1"
            required
            />
          }
          {(field.field_type != "textarea") &&
            <input
              type={field.field_type}
              name={field.field_name}
              id={field.field_name}
              placeholder={field.field_placeholder}
              required
            />
          }
        </FieldsetContactForm>
      ))}
      <StyledButton
        link_text="Send"
        button_color={theme.colors.yellow}
        link_type="a"
        link_url={`${target}?subject=Website Contact Form`}
        flatStyle={true}
      />
    </form>
  </WrapperContactForm>
);

export default ContactForm;

ContactForm.propTypes = {
  title: PropTypes.object.isRequired,
  target: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
};
