import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';

import theme from '../../config/theme';
const StyledWrapper = styled.div`
  position:relative;
  .styledButtonBorder{
    display:none;
  }
  /* &.offsetStyle{
    &:active{
      .styledButtonBorder{
        display:block;
      }
      button{
        transform:translate(-0.25rem, -0.25rem);
      }
    }
  } */
  &.offsetStyle{
    .styledButtonBorder{
      display:block;
      transition:all 0.25s ease-out;
    }
    button{
      transition:all 0.25s ease-out;
      transform:translate(-0.25rem, -0.25rem);
    }
    &:hover{
      .styledButtonBorder{
        border-color:transparent;
      }
      button{
        /* transform:translate(0, 0); */
        transform:translate(0, 0);
      }
    }
    &:active{
      .styledButtonBorder{
        display:none;
      }
      button{
        /* transform:translate(0, 0); */
        transform:translate(0, 0);
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
      line-height:0;
    }
  }
`;
const StyledButtonItem = styled.button`
  border: none;
  background-color: ${props => props.theme.primary};
  ${theme.font.styledButton};
  padding: 0;

  .styledItem {
    padding: 1.333rem 1.55rem;
    &,
    &:visited,
    &:link {
      color: ${props => {
        if (props.textColor) {
          return props.textColor;
        }
        if (props.bgcolor === props.theme.colors.lightPink) {
          return props.theme.colors.greyDark;
        } else {
          return props.theme.colors.white;
        }
      }};
    }
    &:hover {
      color: ${props => {
      if (props.bgcolor === props.theme.colors.lightPink) {
        return props.theme.colors.greyDark;
      } else {
        return props.theme.colors.bg;
      }
    }};
    }
  }

  background-color: ${props => (props.bgcolor ? props.bgcolor : props.theme.colors.greyDark)};
  .styledItem {
    transition: transform 0.1s ease-out;
    display: inline-block;
    transform: translateX(0);
    &:hover {
      transform: translateX(-3px);
    }
    @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
      padding:0.65rem 1rem;
    }
    @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
      padding:0.44rem 0.44rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.l}), (max-device-width: ${props => props.theme.breakpoint.l}) {
    ${theme.font.styledButton.l};
  }
  @media (max-width: ${props => props.theme.breakpoint.s}), (max-device-width: ${props => props.theme.breakpoint.s}) {
    ${theme.font.styledButton.s};
  }
`;
const StyledButtonBorder = styled.div`
  border: 2px solid ${props => (props.bgcolor ? props.bgcolor : props.theme.colors.greyDark)};;
  width:100%;
  height:100%;
  position:absolute;
  z-index:0;
  transform:translate(0.25rem, 0.25rem);
  &:before{
    content: '';
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);
    background: transparent;
    display: block;
    margin-top: -0.25rem;
    margin-left: -0.25rem;
  }
`;
const StyledLink = styled(Link)`
  padding: 1.333rem 1.55rem;
`;
const StyledAnchor = styled.a`
  padding: 1.333rem 1.55rem;
`;
const StyledDiv = styled.div`
  padding: 1.333rem 1.55rem;
`;
const StyledButton = ({ className, button_color, link_url, link_text, text_color, link_type, flatStyle }) => {
  switch (button_color) {
  case 'Light Pink':
    button_color = theme.colors.lightPink;
    break;
  case 'White':
  case 'Black':
  case 'Pink':
  case 'Yellow':
    button_color = theme.colors[button_color.toLowerCase()];
    break;
    // default:
    // button_color = theme.colors["yellow"];
  }
  if (button_color) {
    button_color = button_color.toUpperCase();
  }
  let link = (
    <StyledLink className="styledItem" to={link_url}>
      {ReactHtmlParser(link_text)}
    </StyledLink>
  );
  if (link_type && link_type === 'a') {
    link = (
      <StyledAnchor className="styledItem" href={link_url}>
        {ReactHtmlParser(link_text)}
      </StyledAnchor>
    );
  }
  return (
    <StyledWrapper className={"wrapperStyledButton" + ((!flatStyle)?" offsetStyle":"")}>
      <StyledButtonBorder bgcolor={button_color} className="styledButtonBorder"></StyledButtonBorder>
      <StyledButtonItem className={className} bgcolor={button_color} textColor={text_color}>
        {link_url && link}
        {!link_url && <StyledDiv className="styledItem">{ReactHtmlParser(link_text)}</StyledDiv>}
      </StyledButtonItem>
    </StyledWrapper>
  );
};

export default StyledButton;

StyledButton.propTypes = {
  text_color: PropTypes.string,
  button_color: PropTypes.string,
  link_url: PropTypes.string,
  link_text: PropTypes.string.isRequired,
  link_type: PropTypes.string,
  flatStyle: PropTypes.bool,
};
