const theme = {
  colors: {
    primary: '#282828',
    primaryLight: '#ff2400',
    primaryDark: '#3d0435',
    bg: '#fff',
    text: '#282828',
    textInvert: '#fff',
    white: '#fff',
    greyLight: '#B4B9C1',
    greyMedium: '#414141',
    greyDark: '#282828',
    yellow:'#e5a300',
    pink:'#e2ccd8',
    lightPink:'#F2EDF1',
    red:'#ff2400',
    currentRed:'#ff2400',
    black:'#282828'
  },
  fontFamily: {
    body: `'korolev', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    heading: `'korolev', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  },
  font:{
    bodySize:'2.22rem',
    h1:{
      'font-size':'4.44rem',
      'letter-spacing':0,
      'text-transform': 'uppercase',
      'line-height': '1em',
      'font-weight':'bold',
      'l':{
        'font-size':'2.77rem',
        'word-wrap':'normal'
      },
      'm':{
        'font-size':'3.33rem',
        'word-wrap':'normal'
      },
      's':{
        'font-size':'1.66rem',
        'line-height':'1.66rem'
      },
      's_alt':{
        'font-size':'1.44rem'
      }
    },
    h2:{
      'font-size':'3.33rem',
      'letter-spacing':0,
      'text-transform': 'uppercase',
      'line-height': '1em',
      'word-wrap':'normal',
      'l':{
        'font-size':'1.11rem',
      }
    },
    h3:{
      'font-size':'2.77rem',
      'letter-spacing':'0.033em',
      'line-height':'3.4rem',
      'l':{
        'font-size':'1.11rem',
      },
      's':{
        'font-size':'0.66rem',
      }
    },
    subtitle:{
      'font-size':'1.66rem',
      'letter-spacing':'0.03em',
      'font-weight': 'lighter',
      'text-transform':'none',
      'line-height':'2.5rem',
      'l':{
        'font-size':'1.11rem',
        'line-height':'1.66rem',
      },
      's':{
        'font-size':'0.88rem',
        'line-height':'1.11rem',
      }
    },
    tags:{
      'font-size': '1.16rem',
      'font-weight':'bold',
      'font-family': '"korolev-condensed", "korelev", sans',
      'text-transform':'uppercase'
    },
    projectTitle:{
      'font-size': '1.66rem',
      'letter-spacing': '0.1em',
      'text-transform': 'uppercase',
      'font-weight': 'bold',
      'l':{
        'font-size':'1.55rem',
      },
      's':{
        'font-size':'1rem',
      }
    },
    largeBody:{
      'font-size':'2.22rem',
      'letter-spacing':'0.03em',
      'line-height': '3.25rem',/*changed for category page */
      'l':{
        'font-size':'1.11rem',
        'line-height':'1.38rem',
      }
    },
    styledButton:{
      'font-size':'1.388rem',
      'line-height':'1rem',
      'font-weight':'bold',
      'text-transform':'uppercase',
      'l':{
        'font-size':'1.11rem',
        'font-family': '"korolev-condensed", "korelev", sans',
      },
      's':{
        'font-size':'0.833rem',
      }
    },
    paragraphCopy:{
      'font-size':'1rem',
      'line-height':'1.66em',
      'l':{
        'font-size':'0.66rem',
      },
      's':{
        'font-size':'0.77rem',
        'line-height':'1.11rem'
      }
    },
    locationCopy:{
      'font-size':'0.88rem',
      'line-height':'1.076rem',
      'l':{
        'font-size':'0.66rem',
      }
    }
  },
  heightHeight: '8rem',
  maxWidth: '1800px',
  maxWidthText: '1000px',
  basicModulePadding: {top:"7.4rem", right:"7.6rem", bottom:"7.4rem", left:"7.6rem",
  'l':{top:"2rem", right:"3.6rem", bottom:"2rem", left:"3.6rem"},
  's':{top:"1.77rem", right:"2.1rem", bottom:"1.27rem", left:"2.1rem"}
  },
  breakpoint: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
    xl: '1600px',
    xxl: '1900px',
  },
  'featureHeight':{
    height:'97vh',
    'l':{'height':'44vh'}
  },
};

export default theme;
