import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import BackgroundImage from 'gatsby-background-image';

const Wrapper = styled(Flex)`
  /* flex-basis: calc(99.9% - 4rem); */
  /* background-color: ${props => props.theme.colors.white}; */
  width: 100%;
  min-height: 10rem;
  /* width: calc(99.9% - 4rem); */
  /* text-align: center; */
  position: relative;
  padding: 1.77rem 7.77rem;
  padding: 0;

  a {
    color: ${props => props.theme.colors.greyDark};
  }
  h5{
    padding: 0;
    margin-bottom: 0.77rem;
    color: ${props => props.theme.colors.red};
    font-size:1rem;
    @media (max-width: ${props => props.theme.breakpoint.l}) {
    }
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      ${props => props.theme.font.subtitle.s};
    }
    letter-spacing:0;
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding:3.7rem ${props => props.theme.basicModulePadding.l.left};
    padding:0;
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    margin-top: 0;
    padding:${props => props.theme.basicModulePadding.s.top} ${props => props.theme.basicModulePadding.s.left};
    padding:0;

  }
`;

const Location = styled.div`
  padding: 2.22rem 4.44rem;
  ${props => props.theme.font.locationCopy};

  &:first-child{
    padding-left:0;
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding: 2.22rem 1rem 2.22rem 0;
    ${props => props.theme.font.locationCopy.l};
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    justify-content:space-evenly;

    &:first-child{
      padding-left:4.44rem
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    padding:0 ${props => props.theme.basicModulePadding.s.left} 0 0;
    &:first-child{
      padding-left:0
    }
  }
`;

const Locations = ({ data }) => (
  <Wrapper>
    {data.map((loc, idx) => {
      return (
        <Location key={idx}>
          { ReactHtmlParser(loc.location_name.html) }
          <a href={`tel: ${loc.phone_number}`} title="phone number">{loc.phone_number}</a>
          { ReactHtmlParser(loc.location_address.html) }
        </Location>
      )
    })}
  </Wrapper>
);

export default Locations;

Locations.propTypes = {
  data: PropTypes.array.isRequired
};
