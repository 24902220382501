import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Flex } from 'grid-emotion';
import Image from 'gatsby-image';
import Locations from './Locations';
import ContactForm from './ContactForm';
import StyledButton from './StyledButton';
import SocialLinks from './SocialLinks';

import theme from '../../config/theme';
import ContactFormMailChimp from './ContactFormMailChimp';

const Wrapper = styled.footer`
  background: ${props => props.theme.colors.greyDark};
  color: ${props => props.theme.colors.greyDark};
  a {
    color: ${props => props.theme.colors.bg};
    &:hover {
      color: ${props => props.theme.colors.primaryLight};
    }
  }
`;

const Inner = styled(Flex)`
  background-color: ${props => props.theme.colors.lightPink};
  font-size: 0.6rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.77rem 7.77rem;
  .socialLinksWrapper {
    width: 100%;
    text-align: right;
    a {
      width: 33px;
    }

    @media (max-width: ${props => props.theme.breakpoint.s}) {
      text-align: center;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding: 3.7rem ${props => props.theme.basicModulePadding.l.left};
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    padding: 2.2rem ${props => props.theme.basicModulePadding.s.left};
    flex-direction: column;
    .wrapperStyledButton {
    }
    div:last-child {
    }
  }
`;

const FooterTop = styled(Flex)`
  font-size: 0.8rem;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;

  .bgImage {
    position: absolute !important;
    right: 0;
    width: 30%;
    pointer-events: none;
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    .bgImage {
      width: 50%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    justify-content: center;
    flex-direction: column;
    .bgImage {
      width: 75%;
      margin-right: -35%;
      bottom: 0;
    }
  }
`;

const StyledLink = styled(Link)`
  transform: translateX(0);
  transition: all 200ms ease-out;
  padding: 1rem;
  background-color: ${props => props.theme.colors.greyDark};
  color: ${props => props.theme.colors.white};
  &:hover {
    color: ${props => props.theme.colors.bg};
    transform: translateX(-6px);
  }
`;

const Footer = ({ isProject, data: { template } }) => (
    <Wrapper className="wrapperFooter">
      <FooterTop>
        <ContactFormMailChimp title={template.nodes[0].data.form_title} target={template.nodes[0].data.form_target} fields={template.nodes[0].data.form_fields} />
        <Image fluid={template.nodes[0].data.footer_bg.localFile.childImageSharp.fluid} className='bgImage' />
      </FooterTop>
      <Inner className="innerFooter" p={4}>
        {false &&
          <div>
          {false &&
            template.nodes[0].data.footer_links.map(obj => {
              return <StyledButton key={obj.link_label} activeClassName="active" link_url={obj.link_target} link_text={obj.link_label} text_color={theme.colors.lightPink}></StyledButton>
            })
          }
          </div>
        }
        <Locations data={template.nodes[0].data.locations} />
        <SocialLinks data={template.nodes[0].data.social_links} className="socialLinksWrapper"></SocialLinks>
      </Inner>
    </Wrapper>
  );

// export default Footer;

export default footerStaticQuery => (
    <StaticQuery
    query={graphql`
      query FooterQuery {
        template: allPrismicSitetemplate {
          nodes {
            data {
              footer_links {
                link_label
                link_target
              }
              social_links {
                social_icon {
                  url
                }
                social_link {
                  link_type
                  url
                  target
                }
              }
              footer_bg {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              locations {
                location_name {
                  html
                  text
                }
                location_address {
                  html
                  text
                }
                phone_number
              }
              form_title {
                html
                text
              }
              form_target
              form_fields {
                field_name
                field_type
                field_title {
                  html
                  text
                }
                percentage_width
                field_placeholder
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...footerStaticQuery} />}
  />
);

Footer.propTypes = {
  links: PropTypes.array,
  isProject: PropTypes.bool,
};

Footer.defaultProps = {
  isProject: false,
};
