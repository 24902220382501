import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { StaticQuery, graphql, Link} from 'gatsby';
import { Flex } from 'grid-emotion';
import Navbar from '../components/Navbar';
import SocialLinks from './SocialLinks';

const Logo = styled.h2`
  text-align: center;
  padding: 0;
  margin: 0;
  margin-left:-5px;
  img {
    width: 300px;
    height: 75px;
      transition:all 0.5s;
  }
  transition:all 0.5s;

  @media (min-width: Calc(${props => props.theme.breakpoint.l})) {
    &.scrolled {
      padding-top:0.75rem;
      img {
        width: 150px;
        height: 37px;
        padding-left:15px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    img {
      width: 234px;
      height: 58.5px;
      margin-left:-15px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    img {
      width: 128px;
      height: 32px;
      margin-left:-5px;
    }
  }
`;

const Inner = styled(Flex)`
  margin-top:-8rem;
  background-color: ${props => props.theme.colors.lightPink};
  height: 8rem;
  margin-top:-8rem;

  padding:1.77rem ${props => props.theme.basicModulePadding.right} 1.77rem 6.8rem;
  position:fixed;
  width:100vw;
  z-index:2;

  @media (min-width: Calc(${props => props.theme.breakpoint.l})) {
    &.scrolled {
      height:4rem;
      margin-top:-8rem;
      padding-top:0;
    }
  }
  transition:all 0.5s;
  @media (max-width: ${props => props.theme.breakpoint.l}){
    justify-content: space-between;
    flex-direction: row;
    align-items:center;
    height:124px;
    margin-top:-124px;
    padding: 0 ${props => props.theme.basicModulePadding.l.right} 0 ${props => props.theme.basicModulePadding.l.left};
    text-align: right;
    div:last-child {
      margin-top: 1rem;
    }
  }

  @media (max-width: ${props => props.theme.breakpoint.s}) {
    padding: 0 ${props => props.theme.basicModulePadding.s.right} 0 ${props => props.theme.basicModulePadding.s.left};
    height:75px;
    margin-top:-75px;
  }

  @media (min-width: ${props => props.theme.breakpoint.l}) {
  }
`;


// const Header = ({ data: {template} }) => (
//       <Inner justifyContent="space-between" p={4}>
//         <Logo>
//           <Link to="/">
//             <img src={template.nodes[0].data.site_logo.url} alt={template.nodes[0].data.logo_alt} />
//           </Link>
//         </Logo>
//         <Navbar links={template.nodes[0].data.nav_links} social_links={template.nodes[0].data.social_links}/>
//       </Inner>
// );
class Header extends React.Component  {
  constructor() {
    super();
    this.state = {classState : ''};
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleChange(event) {
    this.setState({classState: event.target.value});
  }

  handleScroll = ev => {
    if (ev.pageY > 100){
      this.handleChange({target:{value:"scrolled"}});
    }else{
      this.handleChange({target:{value:""}});
    }
  };
  render() {
    const { data } = this.props;
    return (
            <Inner className={this.state.classState} justifyContent="space-between" p={4}>
              <Logo className={this.state.classState} >
                <Link to="/">
                  <img src={data.template.nodes[0].data.site_logo.url} alt={data.template.nodes[0].data.logo_alt} />
                </Link>
              </Logo>
              <Navbar links={data.template.nodes[0].data.nav_links} social_links={data.template.nodes[0].data.social_links}/>
            </Inner>
    )
  }
}
export default headerStaticQuery => (
<StaticQuery
  query={graphql`
    query HeaderQuery {
      template: allPrismicSitetemplate {
        nodes {
          data {
            logo_alt
            site_logo {
              localFile {
                absolutePath
              }
              alt
              url
            }
            nav_links {
              link_label
              link_target
            }
            social_links {
              social_icon {
                url
              }
              social_link {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
  `}
    render={data => <Header data={data} {...headerStaticQuery} />}
  />
);

// export default Header;
Header.propTypes = {
  data: PropTypes.shape({
    projects: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
    template: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
};
