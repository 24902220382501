import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';

const CarouselCaption = styled(Flex)`
  font-size: 1rem;
  background-color: ${props => props.theme.colors.lightPink};
  width: 42.2vw;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  padding: 1rem 1.5rem;
  &:after {
    content: '';
    position: absolute;
    border-bottom: 10px solid #e5a300;
    width: 100%;
    margin-left: -1.5rem;
    bottom: -15px;
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    width: 54.8%;
    left: 36%;
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    width: 100%;
    left: 0;
  }
`;

const StaticImage = ({ data: { files }, image_src, className }) => {
  // console.log('asdasdasd', image_src, files);
  const path = files.nodes.find(e => unescape(e.url) === unescape(image_src));

  return <img className={className || 'manualImage'} src={path.fields.path || image_src} />;
};

// export default StaticImage;

StaticImage.propTypes = {
  image_src: PropTypes.string,
  className: PropTypes.string,
};

export default props => (
  <StaticQuery
    query={graphql`
      query StaticQuery {
        files: allFile(filter: { fields: { path: { ne: null } } }) {
          nodes {
            url
            fields {
              ext
              path
            }
          }
        }
      }
    `}
    render={data => <StaticImage data={data} {...props} />}
  />
);
