module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Current Studios', // Navigation and Site Title
  siteTitleAlt: 'Current Studios', // Alternative Site title for SEO
  siteUrl: 'http://currentstudios-dev.s3-website-us-east-1.amazonaws.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/mesh-logo_red.png', // Used for SEO and manifest
  siteDescription: 'We are experts in interactive digital content creation. Our work includes Augmented Reality [AR] development, mobile app development, Virtual Reality, Oculus development, Animation, Video and TV production. We are digital problem solvers who believe creative teams and programming brains working together lead to better, more innovative solutions. Our diverse mix of game developers, animation teams, sound engineers and UI/UX teams is all under one roof is, by design.',
  author: 'FireBurnsUp', // Author for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@currentstudios', // Twitter Username
  ogSiteName: 'currentstudios', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  themeColor: '#ff2400',
  backgroundColor: '#350d27',
};
