/* eslint no-unused-expressions: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectGlobal } from 'react-emotion';
import styled from 'react-emotion';
import { ThemeProvider } from 'emotion-theming';

import Header from './Header';
import Footer from './Footer';
import SEO from './SEO';

import theme from '../../config/theme';

injectGlobal`
*, *:before, *:after {
  box-sizing: inherit;
}
html {
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  body{
    font-size:${theme.font.bodySize};
    h1, h2, h3, h4, h5, h6 {
      color: ${theme.colors.primary};
      text-transform:uppercase;
    }
    h1{
      ${theme.font.h1};
      line-height: 1em; /* */
    }
    h4{
      ${theme.font.subtitle};
      text-transform: uppercase;
    }
    h5{
      font-size: 1.66rem;
      text-transform: uppercase;
      letter-spacing: 0.2em; /*XDunits/ 1000 * fontsize*/
    }
    h6{
      ${theme.font.paragraphCopy};
      line-height:1.1rem;
      @media (max-width: ${theme.breakpoint.l}), (max-device-width: ${theme.breakpoint.l}) {
        ${theme.font.paragraphCopy.l};
      }
    }
    .colorRed{
      color: ${theme.colors.red};
    }
    .boxColor_Pink,.boxColor_Light-Pink{
      &, h1, h2, h3, h4, h5, h6{
        color: ${theme.colors.text};
      }
    }
    .boxColor_Pink{
      background-color: ${theme.colors.pink};
    }
    .boxColor_Light-Pink{
      background-color: ${theme.colors.lightPink};
    }
    .boxColor_Yellow, .boxColor_Black{
      &, h1, h2, h3, h4, h5, h6{
        color: ${theme.colors.white};
      }
    }
    .boxColor_Yellow{
      background-color: ${theme.colors.yellow};
    }
    .boxColor_Black{
      background-color: ${theme.colors.black};
    }
    .defaultModulePadding{
      padding: ${theme.basicModulePadding.top} ${theme.basicModulePadding.right} ${theme.basicModulePadding.bottom} ${theme.basicModulePadding.left};
    }
    form{
      label{
        clear: both;
        width: 100%;
        display: block;
        padding-top:1rem;
      }
      fieldset{
        padding:1rem;
      }
      input, textarea {
        padding: 0.6rem 0.88rem;
        outline: none;
        border: 1px solid black;
        ${theme.font.paragraphCopy};
        width: 100%;
        max-height:53px;
        -webkit-appearance: none;
        @media (max-width: ${theme.breakpoint.l}), (max-device-width: ${theme.breakpoint.l}) {
          ${theme.font.paragraphCopy.l};
          max-height:unset;
        }
        @media (max-width: ${theme.breakpoint.s}), (max-device-width: ${theme.breakpoint.s}) {
          padding: 0.4rem 0.8rem;
        }
        &[type=checkbox]{
          width:1rem;
          height:1rem;
          background:white;
          margin:1rem;
          border-radius:3px;

        }
        &[type=submit]{
          border: none;
          background-color: ${theme.colors.yellow};
          ${theme.font.styledButton};
          padding: 1.333rem 1.55rem;
          width:auto;
          cursor:pointer;
          color:white;
          transition:0.1s background-color ease-out;
          &:hover{
            background-color: ${theme.colors.primary};
            color:white;
          }
        }
        @media (min-width: ${theme.breakpoint.m}) {
        }
        &:focus {
          border: 2px solid ${theme.colors.yellow};
          box-shadow: 0 0 3px #ffffff;
        }
      }
    }
    .hoverOverlay{
      &:hover{
        opacity:1;
        .plusSymbol{
          &:before{
            height:7vw;
            max-height:132px;
            top:50%;
            opacity:1;
          }
          &:after{
            width:7vw;
            max-width:132px;
            left:50%;
            opacity:1;
          }
        }
      }
    }
    img.manualImage{/*images that need an img tag, like animated gifs*/
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }
}
@media (max-width: ${theme.breakpoint.l}), (max-device-width: ${theme.breakpoint.l}) {
  html {
    body{
      h1 {
        font-size: 5.5rem
      }
      h2 {
        font-size: 2.369rem;
      }
      h3 {
        font-size: 1.777rem;
      }
      h4 {
        font-size: 1.333rem;
      }
      h5 {
        ${theme.font.h3.l}
      }
      .defaultModulePadding{
        padding: ${theme.basicModulePadding.l.top}  ${theme.basicModulePadding.l.left} ${theme.basicModulePadding.l.bottom} ${theme.basicModulePadding.l.right};
      }
    }
  }
}
@media (max-width: ${theme.breakpoint.s}), (max-device-width: ${theme.breakpoint.s}) {
  html {
    body{
      h1 {
        font-size: 2.441rem
      }
      h2 {
        font-size: 1.953rem;
      }
      h3 {
        font-size: 1.563rem;
      }
      h4 {
        font-size: 1.25rem;
      }
      h5 {
        ${theme.font.h3.s}
      }
      .defaultModulePadding{
        padding: ${theme.basicModulePadding.s.top}  ${theme.basicModulePadding.s.left} ${theme.basicModulePadding.s.bottom} ${theme.basicModulePadding.s.right};
      }
    }
  }
}
body {
  color: ${theme.colors.text};
  background-color: ${theme.colors.bg};
  min-width: 300px;
}
::selection {
  color: ${theme.colors.bg};
  background-color: ${theme.colors.text};
}
a {
  color: ${theme.colors.primary};
  transition: all 0.4s ease-in-out;
  text-decoration: none;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
  &:hover, &:focus {
    color: inherit;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
}
.defaultPage{
  .contentSectionSection:first-child {
    margin-top:2rem !important;
  }
  #contactUs{
    margin:0 auto;
    max-width:1110px;

    .contentSectionSection{
      form{
        input[type="submit"]{
          line-height:0;
        }
        #mc_embed_signup_scroll{
          display:flex;
          flex-direction:row;
          flex-wrap:wrap;
          h2, .indicates-required, #mergeRow-gdpr{
            display:inline-block;
            flex-basis: 100%;
            flex-grow:0;
            flex-shrink:0;
          }
          & > .mc-field-group{
            flex-basis: Calc(50% - 1rem);
            flex-grow:0;
            flex-shrink:2;
            &:nth-child(even){
              margin-left:0.5rem;
            }
            &:nth-child(odd){
              margin-right:0.5rem;
            }
          }
        }
      }
    }
  }
}

`;

const SiteContainer = styled.div`
margin-top:8rem;
margin-bottom:0;
@media (max-width: ${props => props.theme.breakpoint.l}) {
  margin-top:124px;

}

@media (max-width: ${props => props.theme.breakpoint.s}) {
  margin-top:75px;
}
`;

const Layout = ({ isProject, title, desc, img, social, children, className }) => {

  return(
    <ThemeProvider theme={theme}>
    <React.Fragment>
    <SiteContainer className={className} >
    <Header />
    <SEO title={title} desc={desc} img={img} social={social} />
    {children}
    <Footer isProject={isProject?true:false} />
    </SiteContainer>
    </React.Fragment>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  isProject: PropTypes.bool,
  title: PropTypes.string,
  desc: PropTypes.string,
  img: PropTypes.object,
  social: PropTypes.array,
  className: PropTypes.string
};

export default Layout;
